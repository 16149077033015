// import React from 'react'
// import { graphql, useStaticQuery, Link } from 'gatsby'
// import footerStyles from './footer.module.scss'
// const Footer = () => {
//     const data = useStaticQuery(graphql`
//         query {
//             site {
//                 siteMetadata {
//                     title
//                 }
//             }
//         }
//     `)
//     return(
//         <div>
//             <div className={footerStyles.divHrStyleTop}></div>
//         <footer className={footerStyles.footer}>
//             <p>© 2020 {data.site.siteMetadata.title}</p>
//         </footer>
//         <div className={footerStyles.privacyLinkDiv}>
//         <Link className={footerStyles.privacyLink} to={'./privacy-and-copyright'}>Privacy Policy &amp; Copyright</Link>
//         </div>
//         </div>
//     )
// }
// export default Footer

import React from "react"
import { Link } from "gatsby"
import footerStyles from "./footer.module.scss"

const Footer = () => {
  var date = new Date()
  return (
    <div>
      <footer className={footerStyles.footer}>
        <div className={footerStyles.footerHr}></div>
        <h3 className={footerStyles.footerH3}>
          silvergoldpill.com 2019 - {`${date.getFullYear()}`} ©
        </h3>
        <div className={footerStyles.container}>
          <div>
            <div>
              <Link className={footerStyles.footerItem} to={"/"}>
                Home
              </Link>
            </div>
            <div>
              <Link className={footerStyles.footerItem} to="/about">
                About
              </Link>
            </div>
            <div>
              <Link className={footerStyles.footerItem} to={"/contact"}>
                Contact
              </Link>
            </div>
          </div>
          <div>
            <div>
              <a
                className={footerStyles.footerItem}
                href="privacy-and-copyright"
              >
                Privacy Policy
              </a>
            </div>
            <div>
              <a className={footerStyles.footerItem} href="/contact">
                Advertise with Us
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
